/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useRef, useState } from 'react'
import { Box, TextField, Typography, IconButton, CircularProgress } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
interface Messages {
  role: string
  content: string
}

interface ChatBotProps {
  PromptMessage: string
  character: string
}
const ChatBot: React.FC<ChatBotProps> = ({ PromptMessage, character }) => {
  const [text, setText] = useState<string>('')
  const [disabled, setDisabled] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [messages, setMessages] = useState<Messages[]>([])
  const historyEndRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // scroll to the bottom of the conversation history box when messages change
    if (historyEndRef.current != null) {
      historyEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [messages])

  const defaultMessages = [
    {
      role: 'system',
      content: PromptMessage,
    },
    {
      role: 'user',
      content:
        'Ik ben een detective en zal je veel verschillende vragen stellen over wat er is gebeurd. Mijn belangrijkste taak is om te achterhalen wie de moord heeft gepleegd',
    },
  ]

  /**
   * @description get the OpenAI api key
   */
  const getApiKey = () => {
    const { REACT_APP_OPENAI_KEY: openaiKey = '' } = process.env

    if (openaiKey.length === 0) return openaiKey

    return atob(openaiKey)
  }

  // Request to OpenAI
  const requestOpenAI = async (messages: Messages[]) => {
    const result = await fetch('https://api.openai.com/v1/chat/completions', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getApiKey()}`,
      },
      method: 'POST',
      body: JSON.stringify({
        model: 'gpt-3.5-turbo',
        messages,
      }),
    })
    return await result.json()
  }

  // Function that works with our data
  const fetchData = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)

    let newMessages = []
    if (messages.length === 0) {
      setDisabled(true)
      newMessages = [{ role: 'user', content: `Text: ${text}` }]
    } else {
      newMessages = [...messages, { role: 'user', content: text }]
    }
    const lastUserMessage = text
    setMessages(newMessages)
    setText('')

    try {
      const json = await requestOpenAI([...defaultMessages, ...newMessages])
      // Parse response JSON and send it back in the response
      const answer = json.choices?.[0]?.message
      newMessages = [...newMessages, answer]
      setMessages(newMessages)
      if (answer.content.includes('Warning!') === true) {
        setDisabled(true)
        newMessages = [...newMessages]
        setMessages(newMessages)
        const json2 = await requestOpenAI([...defaultMessages, ...newMessages])
        const answer2 = json2.choices?.[0]?.message
        newMessages = [...newMessages, answer2]
        setText(lastUserMessage)
        setDisabled(false)
      } else {
        setDisabled(false)
      }
      setMessages(newMessages)
    } catch (error) {
      // Log any errors that occur during the request
      console.log('The Error: ', error)
    }
    setIsLoading(false)
  }
  // Conversation history (positive scenario)
  const history = [...defaultMessages, ...messages].slice(2).map((message, index) => (
    <Box key={index} sx={{ px: 2, pb: 1 }}>
      <Typography variant="h6">{message.role === 'user' ? 'Detective' : character}:</Typography>
      <Typography variant="body1">{message.content}</Typography>
    </Box>
  ))

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '90vh',
      }}>
      <Box
        sx={{
          width: '680px',
          maxHeight: '57vh',
          mt: 2,
          overflow: 'auto',
          justifyContent: 'start',
          alignSelf: 'center',
          borderRadius: 2,
          // Style the scrollbar
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '8px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
          },
        }}>
        {history}
        {/* empty div to be used as a reference for scrolling to the bottom */}
        <div ref={historyEndRef} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 0,
          width: '100%',
          marginBlock: '4px',
        }}>
        {isLoading && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 0,
              margin: 1,
              width: '100%',
              height: '50px',
            }}>
            <CircularProgress sx={{ size: '20px', mb: 0, color: '#8879AE' }} />
            <Typography variant="caption">Loading</Typography>
          </Box>
        )}
      </Box>
      <Box sx={{ mt: 'auto', alignSelf: 'center' }}>
        <Box
          sx={{
            backgroundColor: '#FCFCFC',
            width: '680px',
            borderRadius: 2,
            boxShadow: '1px 0px 4px 0px hsla(0, 0%, 0%, 0.24)',
          }}>
          <form
            onSubmit={fetchData}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                fetchData(e)
              }
            }}>
            <Box sx={{ position: 'relative', width: '100%', mb: 5 }}>
              <TextField
                id="chat-input"
                placeholder="Type what you want to ask"
                multiline
                fullWidth
                value={text}
                minRows={6}
                disabled={disabled}
                onChange={(e) => {
                  setText(e.target.value)
                }}
                sx={{ '& fieldset': { border: 'none' }, pl: 1 }}
              />
              <Box sx={{ position: 'absolute', bottom: '0', right: '0' }}>
                <IconButton aria-label="search" type="submit">
                  <SendIcon sx={{ color: '#8879AE' }} />
                </IconButton>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  )
}

export default ChatBot
